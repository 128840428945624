.color-dark-green[data-v-7a0c51c4] {
  color: #31BAA0;
}
.btnEyes[data-v-7a0c51c4] {
    position: absolute;
    top: 20px;
    right: 0;
}
.btn-eyes[data-v-7a0c51c4] {
    display: block;
    overflow: hidden;
    width: 25px;
    height: 25px;
    background-size: 100%;
    box-shadow: unset;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS45NSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDE1Ljk1IDEyIj4KICA8ZyBpZD0icHJldmlldyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDI1IC0yKSI+CiAgICA8cGF0aCBpZD0iUGF0aF8zMDQwMiIgZGF0YS1uYW1lPSJQYXRoIDMwNDAyIiBkPSJNOCwxNGMzLjYsMCw2LjQtMy4xLDcuNi00LjlhMS45NTEsMS45NTEsMCwwLDAsMC0yLjNDMTQuNCw1LjEsMTEuNiwyLDgsMlMxLjYsNS4xLjQsNi45YTEuOCwxLjgsMCwwLDAsMCwyLjJDMS42LDEwLjksNC40LDE0LDgsMTRaTTgsNWEyLjk0NiwyLjk0NiwwLDAsMSwzLDMsMi45NDYsMi45NDYsMCwwLDEtMywzQTIuOTQ2LDIuOTQ2LDAsMCwxLDUsOCwyLjk0NiwyLjk0NiwwLDAsMSw4LDVaIiBmaWxsPSIjZmZmIi8+CiAgPC9nPgo8L3N2Zz4K) no-repeat center center;
}
.btn-eyes.hide[data-v-7a0c51c4] {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iYi1wcmV2aWV3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBpZD0iUGF0aF8zMDQwMCIgZGF0YS1uYW1lPSJQYXRoIDMwNDAwIiBkPSJNMTQuNiw1LjYsNi40LDEzLjhBOC43NTIsOC43NTIsMCwwLDAsOCwxNGMzLjYsMCw2LjQtMy4xLDcuNi00LjlhMS45NTEsMS45NTEsMCwwLDAsMC0yLjNDMTUuNCw2LjUsMTUsNi4xLDE0LjYsNS42WiIgZmlsbD0iI2ZmZiIvPgogIDxwYXRoIGlkPSJQYXRoXzMwNDAxIiBkYXRhLW5hbWU9IlBhdGggMzA0MDEiIGQ9Ik0xNC4zLjMsMTEuNiwzQTcuNDg1LDcuNDg1LDAsMCwwLDgsMkM0LjQsMiwxLjYsNS4xLjQsNi45YTEuOCwxLjgsMCwwLDAsMCwyLjIsMTQuMTE0LDE0LjExNCwwLDAsMCwyLjQsMi43TC4zLDE0LjNhLjk2Ny45NjcsMCwwLDAsMCwxLjRBLjkwOC45MDgsMCwwLDAsMSwxNmEuOTA4LjkwOCwwLDAsMCwuNy0uM2wxNC0xNGEuOTY3Ljk2NywwLDAsMCwwLTEuNEEuOTY3Ljk2NywwLDAsMCwxNC4zLjNabS05LDlBMi43NjgsMi43NjgsMCwwLDEsNSw4LDIuOTQ2LDIuOTQ2LDAsMCwxLDgsNWEyLjc2OCwyLjc2OCwwLDAsMSwxLjMuM1oiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==) no-repeat center center;
}
.textError[data-v-7a0c51c4] {
    color: #FC5F5F;
    font-size: 12px;
    position: absolute;
    top: 100%;
    left: 0;
    margin-bottom: 0;
}
.dateRange[data-v-7a0c51c4] {
    display: flex;
    max-width: 300px;
}
.dateRange > *[data-v-7a0c51c4] {
    display: inline-block;
    flex: 1;
}
.dateRange > span[data-v-7a0c51c4] {
    flex: 0 0 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
}
.dateRange .customeTradeHistory[data-v-7a0c51c4]:first-child {
    background: none;
}
.dateRange .customeTradeHistory[data-v-7a0c51c4]:last-child {
    padding-right: 40px;
}
.tradeHistory[data-v-7a0c51c4] {
    padding: 1rem;
    min-height: calc(100vh - 65px);
    background-color: #12013E;
}
.tradeHistory .container[data-v-7a0c51c4] {
    max-width: 100% !important;
}
.tradeHistory .customBtnSearch[data-v-7a0c51c4]:hover {
    color: #fff;
    background-color: #428bff;
}
.tradeHistory .userStat[data-v-7a0c51c4] {
    border-radius: 14px;
    padding-left: 30px;
    padding-right: 30px;
}
.tradeHistory .userStat.profits[data-v-7a0c51c4] {
    background-color: #2D55FD;
}
.tradeHistory .userStat.revenue[data-v-7a0c51c4] {
    background-color: #31BAA0;
}
.tradeHistory .userStat .wrap[data-v-7a0c51c4] {
    flex: 1;
    display: flex;
    max-width: 300px;
}
.tradeHistory .userStat .icon[data-v-7a0c51c4] {
    flex: 0 0 60px;
    max-width: 60px;
}
.tradeHistory .userStat .icon svg[data-v-7a0c51c4] {
    height: 55px;
}
.tradeHistory .userStat .info[data-v-7a0c51c4] {
    flex: 1;
}
.tradeHistory .userStat .info .name[data-v-7a0c51c4] {
    font-size: 20px;
    color: #ffffff91;
}
.tradeHistory .userStat .info .value[data-v-7a0c51c4] {
    font-size: 30px;
}
.table[data-v-7a0c51c4] {
    color: #fff;
    text-align: center;
    background-color: #060642;
}
.table-bordered thead th[data-v-7a0c51c4] {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    border-bottom-width: 1px;
    border-color: #41416F;
}
.table-bordered td[data-v-7a0c51c4] {
    border-color: #41416F;
}
.icon[data-v-7a0c51c4] {
    width: 24px;
    height: 23px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.icon.UP[data-v-7a0c51c4] {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyMyIgdmlld0JveD0iMCAwIDI0IDIzIj4KICA8ZyBpZD0iR3JvdXBfMTE5MzkiIGRhdGEtbmFtZT0iR3JvdXAgMTE5MzkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMTI4IC0wLjI3NykiPgogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8zNzc0IiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAzNzc0IiB3aWR0aD0iMjQiIGhlaWdodD0iMjMiIHJ4PSIzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC4xMjggMC4yNzcpIiBmaWxsPSIjMzFCQUEwIi8+CiAgICA8ZyBpZD0idHJpYW5nbGUtaGVhZC1hcnJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNC43NDQgMTMuMTk3KSByb3RhdGUoLTQ1KSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzI2MjQ1IiBkYXRhLW5hbWU9IlBhdGggMjYyNDUiIGQ9Ik0xMi43MjksNC4xMzIsOC43MTUuMTE4QS40LjQsMCwwLDAsOC4wMjkuNHYyLjgxSC40YS40LjQsMCwwLDAtLjQuNFY1LjIyYS40LjQsMCwwLDAsLjQuNEg4LjAyOXYyLjgxYS40LjQsMCwwLDAsLjY4Ni4yODRMMTIuNzI5LDQuN2EuNC40LDAsMCwwLDAtLjU2OFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiIGZpbGw9IiNmZmYiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=);
}
.icon.DOWN[data-v-7a0c51c4] {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyMyIgdmlld0JveD0iMCAwIDI0IDIzIj4KICA8ZyBpZD0iR3JvdXBfMTMxNTAiIGRhdGEtbmFtZT0iR3JvdXAgMTMxNTAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMTI4IC0wLjI3NykiPgogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8zNzc0IiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAzNzc0IiB3aWR0aD0iMjQiIGhlaWdodD0iMjMiIHJ4PSIzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC4xMjggMC4yNzcpIiBmaWxsPSIjZmM1ZjVmIi8+CiAgICA8ZyBpZD0idHJpYW5nbGUtaGVhZC1hcnJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOS4wNTUgNC43NDEpIHJvdGF0ZSgzMCkiPgogICAgICA8cGF0aCBpZD0iUGF0aF8yNjI0NSIgZGF0YS1uYW1lPSJQYXRoIDI2MjQ1IiBkPSJNMTIuNzI5LDQuMTMyLDguNzE1LjExOEEuNC40LDAsMCwwLDguMDI5LjR2Mi44MUguNGEuNC40LDAsMCwwLS40LjRWNS4yMmEuNC40LDAsMCwwLC40LjRIOC4wMjl2Mi44MWEuNC40LDAsMCwwLC42ODYuMjg0TDEyLjcyOSw0LjdhLjQuNCwwLDAsMCwwLS41NjhaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIiBmaWxsPSIjZmZmIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K);
}
.bostats[data-v-7a0c51c4] {
    min-height: 292px;
    border-radius: 22px;
    border: 1px solid #41416F;
    background-color: #060642;
}
ul.listTrade[data-v-7a0c51c4] {
    margin-bottom: 30px;
}
ul.listTrade li[data-v-7a0c51c4] {
    border: 1px solid #41416F;
    border-radius: 13px;
    margin-bottom: 18px;
    background-color: #060642;
    padding: 10px 20px;
    overflow: hidden;
}
ul.listTrade li .blockLine[data-v-7a0c51c4] {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 8px;
}
ul.listTrade li .blockLine .block[data-v-7a0c51c4] {
    margin-bottom: 10px;
}
ul.listTrade li .blockLine .block.time[data-v-7a0c51c4] {
    flex: 0 0 140px;
    text-align: right;
}
ul.listTrade li .blockLine .block.copy[data-v-7a0c51c4] {
    flex: 0 0 120px;
    text-align: right;
}
ul.listTrade li .blockLine span.time[data-v-7a0c51c4] {
    font-style: italic;
}
ul.listTrade li .blockLine .button[data-v-7a0c51c4] {
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    padding: 0 20px;
}
ul.listTrade li .blockLine .button.DOWN[data-v-7a0c51c4] {
    background-color: #FC5F5F;
}
ul.listTrade li .blockLine .button.UP[data-v-7a0c51c4] {
    background-color: #31BAA0;
}
ul.listTrade li .blockLine .button.copyOrderId[data-v-7a0c51c4] {
    padding: 0;
}
ul.listTrade li .blockLine .button.copyOrderId span[data-v-7a0c51c4] {
    font-size: 10px;
    font-weight: normal;
    border: 1px solid #41416F;
    border-radius: 5px;
    display: inline-block;
    line-height: 27px;
    padding: 0 20px;
    white-space: nowrap;
}
ul.listTrade li .blockLine .button .icon[data-v-7a0c51c4] {
    display: inline-block;
    vertical-align: middle;
}
ul.listTrade li .blockLine .button .icon.DOWN[data-v-7a0c51c4] {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMC43NDMiIGhlaWdodD0iMTguNzciIHZpZXdCb3g9IjAgMCAyMC43NDMgMTguNzciPgogIDxnIGlkPSJ0cmlhbmdsZS1oZWFkLWFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1Ljg4NCkgcm90YXRlKDMwKSI+CiAgICA8cGF0aCBpZD0iUGF0aF8yNjI0NSIgZGF0YS1uYW1lPSJQYXRoIDI2MjQ1IiBkPSJNMTcsNi4yOTVsLTUuMzMsNS4zMTlhLjUzNC41MzQsMCwwLDEtLjU4NC4xMTMuNTQyLjU0MiwwLDAsMS0uMzMzLS41bC0uMDIxLTMuNzQ0TC41NTEsNy40MjlhLjU0My41NDMsMCwwLDEtLjUzOS0uNTM4TDAsNC43NTJBLjUyOC41MjgsMCwwLDEsLjUzMyw0LjIybDEwLjE4My4wNTZMMTAuNy41MzNBLjUyOC41MjgsMCwwLDEsMTEuMDIzLjA0YS41NC41NCwwLDAsMSwuNTg1LjEyTDE3LDUuNTM3YS41NC41NCwwLDAsMSwuMTYuMzhBLjUyOC41MjgsMCwwLDEsMTcsNi4yOTVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIiBmaWxsPSIjZmZmIi8+CiAgPC9nPgo8L3N2Zz4K) no-repeat center center;
    background-size: 17px 12px;
    width: 16px;
    height: 29px;
    margin-right: 0;
}
ul.listTrade li .blockLine .button .icon.UP[data-v-7a0c51c4] {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMC40NTQiIGhlaWdodD0iMjAuNDU0IiB2aWV3Qm94PSIwIDAgMjAuNDU0IDIwLjQ1NCI+CiAgPGcgaWQ9InRyaWFuZ2xlLWhlYWQtYXJyb3ciIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0wLjA4OSAxMi4wODEpIHJvdGF0ZSgtNDUpIj4KICAgIDxwYXRoIGlkPSJQYXRoXzI2MjQ1IiBkYXRhLW5hbWU9IlBhdGggMjYyNDUiIGQ9Ik0xNy4wMjksNi4zNTcsMTEuNywxMS42NzVhLjUzNC41MzQsMCwwLDEtLjU4NC4xMTMuNTQyLjU0MiwwLDAsMS0uMzMzLS41bC0uMDIxLTMuNzQ0TC41NzgsNy40OTJhLjU0My41NDMsMCwwLDEtLjUzOS0uNTM4TC4wMjcsNC44MTRBLjUyOC41MjgsMCwwLDEsLjU2LDQuMjgzbDEwLjE4My4wNTZMMTAuNzIyLjZBLjUyOC41MjgsMCwwLDEsMTEuMDUuMWEuNTQuNTQsMCwwLDEsLjU4NS4xMkwxNy4wMjUsNS42YS41NC41NCwwLDAsMSwuMTYuMzhBLjUyOC41MjgsMCwwLDEsMTcuMDI5LDYuMzU3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwLjAzNykiIGZpbGw9IiNmZmYiLz4KICA8L2c+Cjwvc3ZnPgo=) no-repeat center center;
    background-size: 17px 12px;
    width: 16px;
    height: 29px;
    margin-right: 0;
}
ul.listTrade li .blockLine .button span[data-v-7a0c51c4] {
    line-height: 29px;
    display: inline-block;
    vertical-align: middle;
    font-size: 17px;
}
@media screen and (max-width: 1300px) {
    .tradeHistory .userStat[data-v-7a0c51c4] {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media screen and (max-width: 1250px) {
    .tradeHistory .icon[data-v-7a0c51c4] {
        flex: 0;
        max-width: 0;
        display: none;
    }
}
@media screen and (max-width: 1023.98px) {
    .blockHistoryMobile .blockLine[data-v-7a0c51c4] {
        margin-bottom: 8px;
    }
    .blockHistoryMobile .copyOrderId span[data-v-7a0c51c4] {
        padding: 4px 20px !important;
        font-size: 14px !important;
    }
    .textError[data-v-7a0c51c4] {
        position: static;
    }
    .tradeHistory[data-v-7a0c51c4] {
        min-height: calc(100vh - 101px);
        margin-bottom: 45px;
    }
    .tradeHistory .container[data-v-7a0c51c4] {
        max-width: 100% !important;
    }
    .tradeHistory .headerContent[data-v-7a0c51c4] {
        align-items: unset !important;
    }
    .tradeHistory .dateRange[data-v-7a0c51c4] {
        max-width: 100%;
        margin-bottom: 1rem;
    }
    .customBtnSearch[data-v-7a0c51c4] {
        width: 100%;
    }
    .endDate .daterangepicker[data-v-7a0c51c4] {
        left: 0 !important;
    }
}
@media screen and (max-width: 992px) {
    .tradeHistory .icon[data-v-7a0c51c4] {
        flex: 0 0 60px;
        max-width: 60px;
        display: unset;
    }
}
.mw-127px[data-v-7a0c51c4] {
    max-width: 127px;
}


.colorWhite67[data-v-b6d115ca] {
  color: rgba(255, 255, 255, 0.67);
}
.circle[data-v-b6d115ca] {
    color: #fff;
    width: 151px;
    height: 151px;
    border-radius: 50%;
    border: 15px solid #B9C7FF;
}
.textTrade[data-v-b6d115ca] {
    padding-top: 1rem;
    padding-left: 3rem;
}
.title[data-v-b6d115ca] {
    color: #0C3653;
    font-weight: bold;
    margin-bottom: 0;
}
.title.titleSm[data-v-b6d115ca] {
    font-size: 1.125rem;
}
.borderRightColor[data-v-b6d115ca] {
    border-right: 1px solid rgba(255, 255, 255, 0.22);
}
.wrap-chart[data-v-b6d115ca] {
    border-radius: 5px;
    position: relative;
}
.wrap-chart .chart-instance[data-v-b6d115ca] {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 5px;
}
.wrap-chart .chart-instance .label[data-v-b6d115ca] {
    font-size: 16px;
    vertical-align: middle;
}
.wrap-chart .chart-instance .label > span.round[data-v-b6d115ca] {
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    margin-right: 5px;
}
.wrap-chart .chart-instance .label > span.round[data-v-b6d115ca]:after {
    position: absolute;
    content: '';
    display: block;
    overflow: hidden;
    width: 16px;
    height: 16px;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background-color: #12013e;
}
.wrap-chart .chart-instance p.name[data-v-b6d115ca] {
    font-size: 16px;
}
.wrap-chart .chart-instance p.value[data-v-b6d115ca] {
    font-size: 20px;
    font-weight: bold;
}
.nodata[data-v-b6d115ca] {
    height: 200px;
}
@media screen and (max-width: 767.98px) {
    .borderRightColor[data-v-b6d115ca] {
        border-width: 0;
    }
    .wrap-chart .wrap[data-v-b6d115ca] {
        text-align: center;
    }
}
@media (max-width: 1024.98px) {
    .nodata[data-v-b6d115ca] {
        height: auto;
    }
}

.title[data-v-417299ea] {
                          color: #0C3653;
                          font-weight: bold;
                          margin-bottom: 0;
                      }
.title.titleSm[data-v-417299ea] {
    font-size: 1.125rem;
}
.borderRightColor[data-v-417299ea] {
    border-right: 1px solid rgba(255, 255, 255, 0.22);
}
.wrap-chart[data-v-417299ea] {
    background: #12013e;
    border-radius: 5px;
    position: relative;
}
.wrap-chart .chart-instance[data-v-417299ea] {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 5px;
}
.wrap-chart .chart-instance .label[data-v-417299ea] {
    font-size: 16px;
    vertical-align: middle;
}
.wrap-chart .chart-instance .label > span[data-v-417299ea] {
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    margin-right: 5px;
}
.wrap-chart .chart-instance .label > span[data-v-417299ea]:after {
    position: absolute;
    content: '';
    display: block;
    overflow: hidden;
    width: 16px;
    height: 16px;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background-color: #12013e;
}
.wrap-chart .chart-instance p .name[data-v-417299ea] {
    font-size: 17px;
    text-transform: uppercase;
}
.wrap-chart .chart-instance p .value[data-v-417299ea] {
    font-size: 16px;
    font-weight: bold;
    margin: 8px;
}
.customChartPercent[data-v-417299ea] {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 10px;
    background-color: #FF2A55;
    border-radius: 10px;
    position: relative;
}
.customChartPercent.nodata[data-v-417299ea] {
    background-color: #B9C7FF;
}
.customChartPercent .percentUp[data-v-417299ea] {
    position: absolute;
    top: 0;
    right: 0;
    height: 10px;
    width: 0px;
    background-color: #31BAA0;
    border-radius: 10px;
    transition: width 300ms ease-in-out;
}
@media screen and (max-width: 767.98px) {
    .borderRightColor[data-v-417299ea] {
        border-width: 0;
    }
}

body{
    font-family: Roboto,Noto Sans,-apple-system,BlinkMacSystemFont,sans-serif;
}
