.boxAuthentication[data-v-682ccdfa] {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  transition: opacity 0.3s ease 0.3s, left 0s ease 0.7s;
}
.boxAuthentication.show[data-v-682ccdfa] {
    right: 0;
}
.boxAuthentication.show .contentAuthentication[data-v-682ccdfa] {
    transform: translateX(0);
}
.boxAuthentication .contentAuthentication[data-v-682ccdfa] {
    position: relative;
    margin-left: auto;
    max-width: 428px;
    height: 100%;
    padding: 2rem;
    background-color: #060642;
    transform: translateX(428px);
    transition: transform 0.3s ease 0.3s;
}
.boxAuthentication .contentAuthentication .close-page[data-v-682ccdfa] {
    position: static !important;
}
.auth-left-col[data-v-682ccdfa] {
    padding: 0 1rem;
}
.bg-login[data-v-682ccdfa] {
    background-color: #fff !important;
}
.white-theme span.colorGray.des-ita[data-v-682ccdfa] {
    color: #fff;
}
.white-theme .logoLogin[data-v-682ccdfa] {
    display: block;
    height: 40px;
    width: 170px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
}
.white-theme .md-field label[data-v-682ccdfa] {
    color: rgba(255, 255, 255, 0.4);
}
.white-theme .regform[data-v-682ccdfa] {
    max-width: 473px;
}
.white-theme .regform form[data-v-682ccdfa] {
    border-radius: 7px;
    background-color: #fff;
    padding: 10px 3rem;
}
.white-theme .regform form h2[data-v-682ccdfa] {
    font-size: 30px;
    color: #000;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}
.white-theme .text-orange[data-v-682ccdfa] {
    color: #2177ff;
}
.white-theme .button.btn-primary.btn-active[data-v-682ccdfa] {
    background-color: #2177ff;
}
.white-theme .button.btn-primary.btn-active[data-v-682ccdfa]:hover {
    background-color: #2177ff;
}
.white-theme .button.btn-gray.btn-border[data-v-682ccdfa] {
    border: 1px solid #fff;
    background-color: transparent;
}
.white-theme .md-field[data-v-682ccdfa] {
    padding-top: 10px;
    padding-bottom: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.white-theme .md-field .md-error[data-v-682ccdfa] {
    top: 100%;
    max-width: 335px;
}
.white-theme .md-field .md-input[data-v-682ccdfa],
.white-theme .md-field .md-textarea[data-v-682ccdfa] {
    width: 100%;
}
.white-theme .md-field label[data-v-682ccdfa] {
    position: static;
    padding: 0;
}
.white-theme .md-field .md-input[data-v-682ccdfa],
.white-theme .md-field .md-textarea[data-v-682ccdfa] {
    height: 40px;
    line-height: 40px;
}
@media (max-width: 1024px) {
    .white-theme .contentAuthentication[data-v-682ccdfa] {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .white-theme .regform form[data-v-682ccdfa] {
        padding: 2rem;
    }
    .white-theme .regform form .nickName[data-v-682ccdfa] {
        margin-bottom: 40px;
    }
    .white-theme .regform form .nickName .md-error[data-v-682ccdfa] {
        max-width: 240px;
    }
}
@media (max-width: 610px) {
    .auth-left-col[data-v-682ccdfa] {
        width: 100% !important;
        min-height: 550px;
        overflow-y: scroll;
    }
    .auth-left-col .logoLogin[data-v-682ccdfa] {
        top: 15px !important;
    }
    .auth-right-col[data-v-682ccdfa] {
        display: none;
    }
    #loginForm[data-v-682ccdfa] {
        height: 100%;
        padding-top: 120px !important;
    }
    .siginButton[data-v-682ccdfa] {
        position: absolute;
        bottom: 4rem;
        left: 0;
    }
    .bottomText[data-v-682ccdfa] {
        margin-bottom: 1.5rem !important;
        text-align: center;
    }
    .close-page[data-v-682ccdfa] {
        color: black;
        font-size: 45px;
        top: 10px;
        right: 10px;
    }
}
@media (max-width: 1024.98px) {
    .boxAuthentication .close-page[data-v-682ccdfa] {
        color: #fff;
        background-color: transparent;
    }
}
.btn-resendemail[data-v-682ccdfa] {
    padding: 14px 2.5rem;
    color: #fff;
    font-weight: bold;
    opacity: 1;
    background: #2177FF;
    text-align: center;
}
.btn-resendemail[data-v-682ccdfa]:disabled {
    background: #6c757d;
}
